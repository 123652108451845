import React from 'react';
import styles from './FleetViewPageHeader.module.scss';
import Select, {
  SelectOptionType,
} from '../../../aurora/components/Select/Select';
import {
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from '../../../aurora/components/Button/Button';
import { IconNotification3Line } from '../../../aurora/icons';
import { isArtyc, selectAuth } from '../../../state/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { setSelectedCompany } from '../../../state/fleetView';
const FleetViewPageHeader: React.FC<{}> = () => {
  const companiesState = useSelector((state: RootState) => state.companies);
  const companies = companiesState.data;
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  if (auth === null) {
    return null;
  }
  const isArtycUser = isArtyc(auth);

  const selectedCompany = useSelector(
    (state: RootState) => state.fleetView.selectedCompany
  );

  const viewOptions: SelectOptionType[] = companies
    .filter((company) => company.companyName !== 'Artyc')
    .map((company) => ({
      value: company._id,
      label: company.companyName,
    }));

  const artycCompany = companies.find(
    (company) => company.companyName === 'Artyc'
  );
  if (artycCompany !== undefined) {
    viewOptions.unshift({
      value: null,
      label: 'Admin View',
    });
  }

  const handleCompanyChange = (selectedOption: SelectOptionType<string>) => {
    dispatch(setSelectedCompany(selectedOption.value));
  };

  return (
    <div className={styles.header}>
      <div className={styles.leftText}>
        <div className={styles.leftTextTitle}>Fleet View</div>
        <div className={styles.leftTextDescription}>
          Monitor and track your fleet of shippers in real-time across all
          locations.
        </div>
      </div>
      <div className={styles.buttonGroup}>
        {isArtycUser && (
          <Select
            options={viewOptions}
            placeholder="Select View"
            managedValue={selectedCompany}
            onChange={handleCompanyChange}
          />
        )}
        <Button
          label=""
          style={ButtonStyle.Stroke}
          type={ButtonType.Neutral}
          iconLeft={IconNotification3Line}
          onClick={() => {
            // TODO(fleet-view): Implement this
            console.log('clicked');
          }}
          size={ButtonSize.Small}
        />
      </div>
    </div>
  );
};

export default FleetViewPageHeader;
