// functions for calculating map bounds

import { FeatureCollection, Feature } from 'geojson';
import { CameraOptions } from 'mapbox-gl';

export const mergeBounds = (
  accBounds: [[number, number], [number, number]],
  bounds: [[number, number], [number, number]]
): [[number, number], [number, number]] => {
  return [
    [
      Math.min(accBounds[0][0], bounds[0][0]),
      Math.min(accBounds[0][1], bounds[0][1]),
    ],
    [
      Math.max(accBounds[1][0], bounds[1][0]),
      Math.max(accBounds[1][1], bounds[1][1]),
    ],
  ];
};

export const calculateBoundingBox = (
  geojson: FeatureCollection
): [[number, number], [number, number]] => {
  if (geojson.features.length === 0) {
    return [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ];
  }

  return geojson.features.reduce(
    (
      acc: [[number, number], [number, number]],
      feature: Feature
    ): [[number, number], [number, number]] => {
      if (feature.geometry.type === 'Point') {
        const [long, lat] = feature.geometry.coordinates;
        return [
          [Math.min(acc[0][0], long), Math.min(acc[0][1], lat)],
          [Math.max(acc[1][0], long), Math.max(acc[1][1], lat)],
        ];
      }
      if (feature.geometry.type === 'LineString') {
        return feature.geometry.coordinates.reduce((bounds, [long, lat]) => {
          return [
            [Math.min(bounds[0][0], long), Math.min(bounds[0][1], lat)],
            [Math.max(bounds[1][0], long), Math.max(bounds[1][1], lat)],
          ] as [[number, number], [number, number]];
        }, acc);
      }
      return acc;
    },
    [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ] as [[number, number], [number, number]]
  );
};

export const isValidLatLong = (lat: number, long: number) => {
  return (
    lat >= -90 &&
    lat <= 90 &&
    long >= -180 &&
    long <= 180 &&
    // temporary condition until we change shipper behavior
    !(lat === 0 && long === 0)
  );
};

export const boundOptions = (): CameraOptions => {
  return {
    maxZoom: 15,
    padding: {
      top: 20,
      bottom: 40,
      left: 20,
      right: 20,
    },
  };
};
