import React, { useState } from 'react';
import { setBannerDismissed } from '../../state/events';
import { FiltersBar } from './components/FiltersBar';
import { useNavigate } from 'react-router-dom';
import { endOfDay } from 'date-fns';
import PageHeader from '../../components/layout/PageHeader';
import DashboardCard, { FetchDataFunction } from './components/DashboardCard';
import NotificationBanner from '../../components/layout/NotificationBanner';
import SegmentsApi from '../../api/segmentsApi';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../state/auth';
import { useSelector, useDispatch } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { RootState } from '../../state/store';
import styles from './DashboardPage.module.scss';
import { selectIsCaas } from '../../state/companies';

const DashboardPage: React.FC<{}> = () => {
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const { newEventCount, bannerDismissed } = useSelector(
    (state: RootState) => state.events
  );
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(setBannerDismissed());
  };

  const handleContinue = () => {
    navigate('/portal/events');
    setBannerDismissed();
  };

  const isCaas = useSelector(selectIsCaas);
  const hasShippers = isArtyc(auth) || (isCompanyAdmin(auth) && !isCaas);

  interface CardConfig {
    title: string;
    tooltipText: string;
    fetchFunction: FetchDataFunction;
  }

  const cardConfigs: CardConfig[] = [
    {
      title: 'Total shipments',
      tooltipText: 'Total number of shipments',
      fetchFunction: (rangeStart, rangeEnd, companyIdsFilter, forCompanyId) =>
        SegmentsApi.getTotalSegments(
          axios,
          auth,
          rangeStart,
          rangeEnd,
          companyIdsFilter,
          forCompanyId
        ),
    },
    {
      title: 'Hours without excursion',
      tooltipText: 'Total hours from shipments that had no excursions',
      fetchFunction: (rangeStart, rangeEnd, companyIdsFilter, forCompanyId) =>
        SegmentsApi.getHoursWithoutExcursion(
          axios,
          auth,
          rangeStart,
          rangeEnd,
          companyIdsFilter,
          forCompanyId
        ),
    },
    ...(hasShippers
      ? [
          {
            title: 'Maximum shipper utilization',
            tooltipText: 'Total shipments of the most-used shipper',
            fetchFunction: (
              rangeStart,
              rangeEnd,
              companyIdsFilter,
              forCompanyId
            ) =>
              SegmentsApi.getMaxDeviceUtilization(
                axios,
                auth,
                rangeStart,
                rangeEnd,
                companyIdsFilter,
                forCompanyId
              ),
          } as CardConfig,
        ]
      : []),
  ];

  const [rangeStartDate, setRangeStartDate] = useState<string | undefined>();
  const [rangeEndDate, setRangeEndDate] = useState<string | undefined>();
  const [companyIds, setCompanyIds] = useState<string[] | undefined>([]);

  const onFilterDate = (startDate?: Date, endDate?: Date) => {
    startDate !== undefined
      ? setRangeStartDate(startDate.toISOString())
      : setRangeStartDate(undefined);

    endDate !== undefined
      ? setRangeEndDate(endOfDay(endDate).toISOString())
      : setRangeEndDate(undefined);
  };

  const headingText =
    'Welcome' + (auth?.firstName ? `, ${auth.firstName}` : '!');

  const dashboardCards = cardConfigs.map((config) => (
    <DashboardCard
      key={config.title}
      title={config.title}
      tooltipText={config.tooltipText}
      fetchDataFunction={config.fetchFunction}
      rangeStartDate={rangeStartDate}
      rangeEndDate={rangeEndDate}
      forCompanyId={auth?.companyId}
      companyIdsFilter={
        companyIds !== undefined && companyIds.length > 0
          ? companyIds
          : undefined
      }
    />
  ));

  return (
    <div className={styles.dashboardPage}>
      <PageHeader headingText={headingText} />
      {newEventCount > 0 && !bannerDismissed && (
        <NotificationBanner
          sxProps={{ marginBottom: '34px' }}
          title={'Shipper event notification'}
          content={'One or more shippers are in need of attention.'}
          variant={'warning'}
          onContinue={handleContinue}
          onDismiss={handleDismiss}
        />
      )}
      <FiltersBar
        onFilterDate={onFilterDate}
        companyIds={companyIds}
        setCompanyIds={(companyIds: string[] | undefined) =>
          setCompanyIds(companyIds)
        }
      />{' '}
      <div className={styles.dashboardCardContainer}>{dashboardCards}</div>
    </div>
  );
};

export default DashboardPage;
