import React from 'react';
import Splash from '../../components/layout/Splash';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../api/axios';
import { IconLock } from '../../aurora/icons';
import { useDispatch } from 'react-redux';
import { showToast } from '../../aurora/components/Toast/Toast';
import { setAuth } from '../../state/auth';
import jwt_decode from 'jwt-decode';
import SetPasswordForm from './components/SetPasswordForm';
import { UserInfo } from '../../state/auth/types';

const ResetPasswordPage = () => {
  const RESET_PASSWORD_URL = '/auth/password-reset';
  const LOGIN_URL = '/auth/login';

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const displayError = (title: string, text: string) => {
    showToast({ type: 'error', title, text });
  };

  const handleResetError = (err: any) => {
    let errMsg: string;
    if (!err?.response) {
      errMsg = 'Reset Password Failed: No server response';
    } else if (err.response?.status === 400) {
      errMsg = 'Invalid Data';
    } else if (err.response?.status === 401) {
      errMsg = 'Unauthorized';
    } else {
      errMsg = 'Reset Password Failed';
    }
    displayError('Password Update Error', errMsg);
  };

  const handleLoginError = (err: any) => {
    let errMsg: string;
    if (!err?.response) {
      errMsg = 'Login Failed: No server response';
    } else {
      switch (err.response.status) {
        case 400:
          errMsg = 'Missing Email or Password';
          break;
        case 401:
          errMsg = 'Unauthorized';
          break;
        default:
          errMsg = 'Login Failed';
      }
    }
    displayError('Login Error', errMsg);
  };

  const handlePasswordReset = async (
    token: string | undefined,
    password: string
  ) => {
    const sanitizedPassword = password.trim();
    const resetResponse = await axios.post(
      `${RESET_PASSWORD_URL}/${token}`,
      JSON.stringify({ password: sanitizedPassword }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );
    if (resetResponse.status === 200) {
      try {
        const response = await axiosPrivate.post(
          LOGIN_URL,
          JSON.stringify({
            email: resetResponse.data.email,
            password: sanitizedPassword,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );

        const { accessToken } = response.data;
        const { userInfo } = jwt_decode<{ userInfo: UserInfo }>(accessToken);

        dispatch(setAuth({ ...userInfo, accessToken }));
        navigate('/password-reset-success', { replace: true });
      } catch (err: any) {
        handleLoginError(err);
      }
    }
  };

  return (
    <Splash
      card="Set new password"
      subtitle={
        'Your new password must be different to previously used passwords.'
      }
      icon={<IconLock width={28} height={28} />}
    >
      <SetPasswordForm
        token={token}
        submitPassword={handlePasswordReset}
        handleSubmitError={handleResetError}
      />
    </Splash>
  );
};

export default ResetPasswordPage;
