import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCompassDiscoverFill: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <Path
      d="M7.75 15.5C3.60775 15.5 0.25 12.1422 0.25 8C0.25 3.85775 3.60775 0.5 7.75 0.5C11.8922 0.5 15.25 3.85775 15.25 8C15.25 12.1422 11.8922 15.5 7.75 15.5ZM4 7.625L7 8.75L8.125 11.7515L10.75 5L4 7.625Z"
      fill="currentColor"
    />
  </IconBase>
);
