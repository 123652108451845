import React, { ReactNode } from 'react';
import { createContext, useState } from 'react';

export type FeatureFlags = { [flag: string]: boolean };

export interface FeatureFlagContextType {
  featureFlags: FeatureFlags;
  updateFeatureFlags: (callback: () => Promise<FeatureFlags>) => Promise<void>;
  isLoading: boolean;
}

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: {},
  updateFeatureFlags: () => Promise.resolve(),
  isLoading: false,
});

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const updateFeatureFlags = async (callback: () => Promise<FeatureFlags>) => {
    try {
      setIsLoading(true);
      const flags = await callback();
      setFeatureFlags(flags);
    } catch (error) {
      console.error('Error fetching feature flags:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FeatureFlagContext.Provider
      value={{ featureFlags, updateFeatureFlags, isLoading }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContext;
