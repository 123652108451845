// functions for rendering the points onto the map

import mapboxgl from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { mergeBounds } from './mapBounds';
import { addGeoJSONSource, updateSourceGeoJSON } from './mapData';
import { handleMouseEnter, handleMouseLeave } from './mapHandlers';

const renderPointGroup = (
  map: mapboxgl.Map,
  pointGroup: MapPointGroup,
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  if (!map.getSource(pointGroup.groupId)) {
    const bounds = addGeoJSONSource(map, pointGroup);
    map.addLayer(pointGroup.groupStyle);

    const { onHover, onClick } = pointGroup;

    if (onClick !== undefined) {
      map.on('click', pointGroup.groupId, (e) => {
        if (e.features?.[0]?.properties && onClick) {
          onClick(e.features[0].properties);
        }
      });
    }

    if (onHover !== undefined) {
      map.on('mouseenter', pointGroup.groupId, (e) =>
        handleMouseEnter(map, e, popup, onHover)
      );

      map.on('mouseleave', pointGroup.groupId, () =>
        handleMouseLeave(
          map,
          popup,
          pointGroup.groupId,
          onHover.paint?.defaultPaint
        )
      );
    }

    return bounds;
  } else {
    return updateSourceGeoJSON(map, pointGroup);
  }
};

export const renderPointGroups = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  let accBounds: [[number, number], [number, number]] = [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ];

  for (const pointGroup of pointGroups) {
    const bounds = renderPointGroup(map, pointGroup, popup);
    accBounds = mergeBounds(accBounds, bounds);
  }

  return accBounds;
};

export const updatePointGroups = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup
): [[number, number], [number, number]] => {
  let accBounds: [[number, number], [number, number]] = [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ];

  const existingSources = Object.keys(map.getStyle()?.sources || {});
  const currentGroupIds = new Set(pointGroups.map((group) => group.groupId));

  // clear out old sources
  existingSources.forEach((sourceId) => {
    if (!currentGroupIds.has(sourceId) && sourceId !== 'composite') {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    }
  });

  pointGroups.forEach((group) => {
    // updating existing source
    if (map.getSource(group.groupId)) {
      const bounds = updateSourceGeoJSON(map, group);
      accBounds = mergeBounds(accBounds, bounds);
    } else {
      // add new source
      const bounds = renderPointGroup(map, group, popup);
      accBounds = mergeBounds(accBounds, bounds);
    }
  });

  return accBounds;
};
