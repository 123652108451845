import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconMapPin: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 12 14" {...props}>
    <Path
      d="M9.81838 9.21841L5.99998 13.0368L2.18158 9.21841C1.42638 8.46321 0.912087 7.50102 0.703733 6.45352C0.495378 5.40603 0.60232 4.32027 1.01103 3.33356C1.41975 2.34684 2.11188 1.50348 2.99991 0.910121C3.88793 0.316764 4.93196 6.10352e-05 5.99998 6.10352e-05C7.06799 6.10352e-05 8.11203 0.316764 9.00005 0.910121C9.88807 1.50348 10.5802 2.34684 10.9889 3.33356C11.3976 4.32027 11.5046 5.40603 11.2962 6.45352C11.0879 7.50102 10.5736 8.46321 9.81838 9.21841ZM5.99998 6.60001C6.31824 6.60001 6.62346 6.47359 6.84851 6.24854C7.07355 6.0235 7.19998 5.71827 7.19998 5.40001C7.19998 5.08175 7.07355 4.77653 6.84851 4.55149C6.62346 4.32644 6.31824 4.20001 5.99998 4.20001C5.68172 4.20001 5.37649 4.32644 5.15145 4.55149C4.92641 4.77653 4.79998 5.08175 4.79998 5.40001C4.79998 5.71827 4.92641 6.0235 5.15145 6.24854C5.37649 6.47359 5.68172 6.60001 5.99998 6.60001Z"
      fill="currentColor"
    />
  </IconBase>
);
