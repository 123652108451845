import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { selectSnapshotsBySegment } from '../../../../../state/segments';
import { SegmentEntity } from '../../../../../state/segments/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import { calculateMarkerPoints } from './mapUtils';
import { IconCircle, IconMapPin } from '../../../../../aurora/icons';
import { Label } from '../../../../../aurora/typography/Label/Label';
import styles from './MapLegend.module.scss';
import { Button } from '../../../../../aurora/components/Button/Button';
import { Map as MapboxMap } from 'mapbox-gl';

interface Props {
  segment: SegmentEntity;
  shipment: ShipmentEntity;
  map: MutableRefObject<MapboxMap | null>;
}

const MapLegend = ({ segment, shipment, map }: Props) => {
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);

  const markerPoints = calculateMarkerPoints(
    segment,
    shipment,
    snapshotsBySegment[segment._id]
  );
  const { start, here, end } = markerPoints;

  const onClick = (latlng: [number, number]) => {
    if (map.current === null) {
      return;
    }

    map.current.flyTo({
      center: [latlng[1], latlng[0]],
      zoom: Math.max(12, map.current.getZoom()),
    });
  };

  return (
    <div className={styles.container}>
      {start && (
        <Button
          type="primary"
          style="filled"
          size="xxs"
          label="Start"
          iconLeft={IconMapPin}
          onClick={() => onClick(start)}
        />
      )}

      {end && (
        <Button
          type="primary"
          style="filled"
          size="xxs"
          label="End"
          iconLeft={IconMapPin}
          onClick={() => onClick(end)}
          className={styles.end}
        />
      )}

      <div className={styles.locationLegend}>
        <div className={styles.prevIcon}>
          <IconCircle width={12} height={12} />
        </div>
        <Label size="xs" color="sub600">
          Previous Location
        </Label>
      </div>

      {here && (
        <div className={styles.locationLegend}>
          <div className={styles.hereIcon}>
            <IconCircle width={12} height={12} />
          </div>
          <Label size="xs" color="sub600">
            Current Location
          </Label>
        </div>
      )}
    </div>
  );
};

export default MapLegend;
