import React from 'react';
import { useSelector } from 'react-redux';
import {
  IconCircleAlert,
  IconHourglass,
  IconLoading,
} from '../../../../../aurora/icons';
import { selectSnapshotsBySegment } from '../../../../../state/segments';
import { SegmentEntity } from '../../../../../state/segments/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import SnapshotsGraphContainer from '../graph/SnapshotsGraphContainer';
import GraphPlaceholder from '../graph/GraphPlaceholder';
import SnapshotsMapContainer from '../map/SnapshotsMapContainer';

interface Props {
  segment?: SegmentEntity;
  shipment: ShipmentEntity;
  isArtycViewer: boolean;
  isLoading: boolean;
  isError: boolean;
}
const SegmentDataSection = ({
  segment,
  shipment,
  isArtycViewer,
  isLoading,
  isError,
}: Props) => {
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const snapshots = snapshotsBySegment[segment?._id || ''] || [];
  const hasSnapshots = segment !== undefined && snapshots.length > 0;

  if (isLoading) {
    return (
      <GraphPlaceholder
        icon={IconLoading}
        title="Loading"
        subtitle="Please wait while we load the data from the shipper"
      />
    );
  }

  if (isError) {
    return (
      <GraphPlaceholder
        icon={IconCircleAlert}
        title="Error"
        subtitle="Error loading data, please try again"
      />
    );
  }

  if (!hasSnapshots) {
    return (
      <GraphPlaceholder
        icon={IconHourglass}
        title="Awaiting Temperature Data"
        subtitle="Temperature data will appear here once it has been uploaded from the shipper. Please check back later."
      />
    );
  }

  return (
    <>
      <SnapshotsGraphContainer
        segment={segment}
        isArtycViewer={isArtycViewer}
      />
      <SnapshotsMapContainer
        segment={segment}
        shipment={shipment}
        isArtycViewer={isArtycViewer}
      />
    </>
  );
};

export default SegmentDataSection;
