// create clusters of points that are close to each other

import mapboxgl from 'mapbox-gl';
import { LngLat } from 'mapbox-gl';
import { MapPoint } from '../../../../components/map/Map';

const pixelsOverlap = (
  pixel1: mapboxgl.Point,
  pixel2: mapboxgl.Point
): boolean => {
  const OVERLAP_RADIUS_PX = 20;
  return (
    Math.sqrt(
      Math.pow(pixel1.x - pixel2.x, 2) + Math.pow(pixel1.y - pixel2.y, 2)
    ) <= OVERLAP_RADIUS_PX
  );
};

interface Cluster {
  center: LngLat;
  points: MapPoint[];
}
// we want to render the clusters differently
export const createClusters = (
  points: MapPoint[],
  map: mapboxgl.Map
): { clusters: MapPoint[]; singles: MapPoint[] } => {
  const clusters: Cluster[] = [];
  const singles: MapPoint[] = [];

  const pointsWithPixels = points.map((point) => ({
    point,
    pixel: map.project([point.longitude, point.latitude]),
  }));

  const processedPoints = new Set();
  for (let i = 0; i < pointsWithPixels.length; i++) {
    const { point, pixel } = pointsWithPixels[i];

    if (processedPoints.has(point)) {
      continue;
    }

    // find all points that overlap with the current point
    // don't need to check ones that already overlapped with a previous point
    // since that would already be part of that cluster
    const nearbyPoints = [];
    for (let j = i + 1; j < pointsWithPixels.length; j++) {
      const otherPoint = pointsWithPixels[j];
      if (!processedPoints.has(otherPoint.point)) {
        if (pixelsOverlap(pixel, otherPoint.pixel)) {
          nearbyPoints.push(otherPoint.point);
          processedPoints.add(otherPoint.point);
        }
      }
    }
    processedPoints.add(point);

    if (nearbyPoints.length > 0) {
      // Use geographic center for cluster position
      const center = new LngLat(
        [point, ...nearbyPoints].reduce((sum, p) => sum + p.longitude, 0) /
          (nearbyPoints.length + 1),
        [point, ...nearbyPoints].reduce((sum, p) => sum + p.latitude, 0) /
          (nearbyPoints.length + 1)
      );

      clusters.push({
        center,
        points: [point, ...nearbyPoints],
      });
    } else {
      singles.push(point);
    }
  }

  const clusterPoints = clusters.map((cluster) => ({
    latitude: cluster.center.lat,
    longitude: cluster.center.lng,
    properties: {
      point_count: cluster.points.length,
      point_count_str: cluster.points.length.toString(),
      status: cluster.points[0].properties!.status,
      clusterId: cluster.points[0].properties!.serialNumber,
    },
  }));

  return { clusters: clusterPoints, singles };
};

// if a deployed point overlaps with a standby point, offset the right-most one
export const adjustOverlappingPoints = (
  deployedPoints: MapPoint[],
  standbyPoints: MapPoint[],
  map: mapboxgl.Map
) => {
  const OVERLAP_RADIUS_PX = 12;
  const MIN_DISTANCE_PX = OVERLAP_RADIUS_PX * 2;

  standbyPoints.forEach((standbyPoint) => {
    const standbyPixel = map.project([
      standbyPoint.longitude,
      standbyPoint.latitude,
    ]);

    deployedPoints.forEach((deployedPoint) => {
      const deployedPixel = map.project([
        deployedPoint.longitude,
        deployedPoint.latitude,
      ]);

      if (pixelsOverlap(standbyPixel, deployedPixel)) {
        const pointToOffset =
          standbyPoint.longitude > deployedPoint.longitude
            ? standbyPoint
            : deployedPoint;

        const pixelToOffset =
          pointToOffset === standbyPoint ? standbyPixel : deployedPixel;

        const currentDistance = Math.sqrt(
          Math.pow(standbyPixel.x - deployedPixel.x, 2) +
            Math.pow(standbyPixel.y - deployedPixel.y, 2)
        );

        const requiredOffset = MIN_DISTANCE_PX - currentDistance;

        const offsetPixel = {
          x: pixelToOffset.x + requiredOffset,
          y: pixelToOffset.y,
        };

        const offsetLngLat = map.unproject(offsetPixel);
        pointToOffset.longitude = offsetLngLat.lng;
        pointToOffset.latitude = offsetLngLat.lat;
      }
    });
  });
};
