import React from 'react';
import DeploymentStatusBadge from '../DeploymentStatusBadge';
import styles from './FleetViewPopup.module.scss';
import { PointProperties } from './mkPointGroups';
import { IconTemperature, IconBattery } from '../../../../aurora/icons';

const FleetViewPopup = ({ properties }: { properties: PointProperties }) => {
  properties.battery = 12.57;
  properties.tempC = 8.33;

  return (
    <div className={styles.popup}>
      <DeploymentStatusBadge deploymentStatus={properties?.deploymentStatus} />
      <div className={styles.deviceInfo}>
        <div className={styles.serialNumber}>
          {`#${properties?.serialNumber}`}
        </div>
        <div>{properties?.deviceType}</div>
      </div>

      {(properties.battery || properties.tempC) && (
        <div className={styles.tagRow}>
          <div className={styles.tag}>
            <IconBattery width={16} height={16} />
            <div className={styles.tagText}>{`${Math.round(
              properties.battery
            )}%`}</div>
          </div>
          <div className={styles.tag}>
            <IconTemperature width={16} height={16} />
            <div className={styles.tagText}>{`${properties.tempC.toLocaleString(
              'en-US',
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }
            )}°C`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FleetViewPopup;
