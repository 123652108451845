import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconBattery: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 16 16">
    <Path
      d="M6.20005 3.2V2.6C6.20005 2.44087 6.26326 2.28826 6.37578 2.17574C6.48831 2.06321 6.64092 2 6.80005 2H9.20005C9.35918 2 9.51179 2.06321 9.62431 2.17574C9.73683 2.28826 9.80005 2.44087 9.80005 2.6V3.2H11.6C11.7592 3.2 11.9118 3.26321 12.0243 3.37574C12.1368 3.48826 12.2 3.64087 12.2 3.8V13.4C12.2 13.5591 12.1368 13.7117 12.0243 13.8243C11.9118 13.9368 11.7592 14 11.6 14H4.40005C4.24092 14 4.08831 13.9368 3.97578 13.8243C3.86326 13.7117 3.80005 13.5591 3.80005 13.4V3.8C3.80005 3.64087 3.86326 3.48826 3.97578 3.37574C4.08831 3.26321 4.24092 3.2 4.40005 3.2H6.20005ZM8.60005 8V5L5.60005 9.2H7.40005V12.2L10.4 8H8.60005Z"
      fill="currentColor"
    />
  </IconBase>
);
