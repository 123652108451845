import React, { useEffect, useRef } from 'react';
import { FleetViewDevice } from '../../../../state/fleetView/types';
import styles from './FleetViewPageDetails.module.scss';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import FleetViewShipperCard from './FleetViewShipperCard';
import DetailsHeader from './DetailsHeader';
import { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../components/layout/LoadingSpinner';
import TableError from '../../../../components/table/TableError';

interface FleetViewPageDetailsProps {
  devices: FleetViewDevice[];
  onAccordionToggle: (idx: number, isOpen: boolean) => void;
  isLoading: boolean;
  error: Error | undefined;
}

const FleetViewPageDetails: React.FC<FleetViewPageDetailsProps> = ({
  devices,
  onAccordionToggle,
  isLoading,
  error,
}) => {
  const openIdx = useSelector((state: RootState) => state.fleetView.openIdx);
  const detailsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (openIdx !== null && detailsContainerRef.current) {
      const element = detailsContainerRef.current.querySelector(
        `[data-accordion-id="accordion-${openIdx}"]`
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [openIdx]);
  if (isLoading) {
    return (
      <div className={styles.detailsContainer}>
        <DetailsHeader />
        <div className={styles.loadingContainer}>
          <LoadingSpinner width={64} height={64} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.detailsContainer}>
        <DetailsHeader />
        <div className={styles.accordionContainer}>
          <TableError />
        </div>
      </div>
    );
  }

  const sections = devices.map((device) => {
    const { header, body } = FleetViewShipperCard({ device });
    return { header, body };
  });

  return (
    <div className={styles.detailsContainer}>
      <DetailsHeader />
      <div className={styles.accordionContainer} ref={detailsContainerRef}>
        <Accordion
          sections={sections}
          panelClassName={styles.accordionPanel}
          onAccordionToggle={onAccordionToggle}
          className={styles.accordion}
          initialOpenIdx={openIdx ?? undefined}
        />
      </div>
    </div>
  );
};

export default FleetViewPageDetails;
