import React, { ComponentType } from 'react';
import { IconProps } from '../../icons/IconBase';
import { BaseButton, ButtonActionProps } from './BaseButton';
import styles from './Button.module.scss';

export const ButtonType = {
  Primary: 'primary',
  Neutral: 'neutral',
  Error: 'error',
} as const;
type ButtonTypeType = (typeof ButtonType)[keyof typeof ButtonType];

export const ButtonStyle = {
  Filled: 'filled',
  Stroke: 'stroke',
  Ghost: 'ghost',
} as const;
type ButtonStyleType = (typeof ButtonStyle)[keyof typeof ButtonStyle];

export const ButtonSize = {
  Medium: 'md',
  Small: 'sm',
  XSmall: 'xs',
  XXSmall: 'xxs',
} as const;
type ButtonSizeType = (typeof ButtonSize)[keyof typeof ButtonSize];

export type ButtonProps = {
  type: ButtonTypeType;
  style: ButtonStyleType;
  size: ButtonSizeType;
  label: string;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
  className?: string;
} & ButtonActionProps;
export const Button = ({
  type,
  style,
  size,
  className,
  ...rest
}: ButtonProps) => {
  return (
    <BaseButton
      disabledClassName={`${styles.disabled} ${styles[size]}`}
      baseClassName={styles.button}
      variantClassName={`${styles[size]} ${styles[type]} ${styles[style]} ${className}`}
      {...rest}
    />
  );
};
