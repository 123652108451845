import { OptionalField } from '../types';

export enum AddressTypeEnum {
  WAREHOUSE = 'WAREHOUSE',
  USER = 'USER',
  LAB = 'LAB',
}

export interface AddressEntity {
  _id: string;
  addressName: OptionalField<string>;
  attn: OptionalField<string>;
  addressLine1: string;
  addressLine2: OptionalField<string>;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  addressType: AddressTypeEnum;
  phoneNumber: OptionalField<string>;
  latitude: OptionalField<number>;
  longitude: OptionalField<number>;
}

export type NewAddressEntity = Omit<
  AddressEntity,
  '_id' | 'latitude' | 'longitude'
>;
export type NewOrExistingAddressEntity =
  | NewAddressEntity
  | Pick<AddressEntity, '_id'>;
