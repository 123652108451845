// functions for converting point groups to data on the map

import mapboxgl from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { FeatureCollection, Feature } from 'geojson';
import { calculateBoundingBox } from './mapBounds';

/* 
GeoJSON is an open standard file format for representing map data. 
Mapbox web services and APIs serve geospatial data as GeoJSON.

A GeoJSON object will have a `type` property and then other properties depending on the value of type.  Here's an exmaple of how we are using geoJSON:
{
       "type": "FeatureCollection",
       "features": [{
           "type": "Feature",
           "geometry": {
               "type": "Point",
               "coordinates": [102.0, 0.5]
           },
           "properties": {
               timestampArray: string[]; // array of the timestamps for all snapshots that happened at that point
               secSinceStartArray: number[]; // array of all secSinceStart values for snapshots that happened at that point
               indexArray: number[]; // array of the indicies of the snapshots that happened at that point
               pointIndex: number; // index of the Point in the sgement
           }
       }, {
           "type": "Feature",
           "geometry": {
               "type": "LineString",
               "coordinates": [
                   [102.0, 0.0],
                   [103.0, 1.0],
                   [104.0, 0.0],
                   [105.0, 1.0]
               ]
           },
           "properties": {
             // same as above
            }
          }]
}
*/

export const convertPointGroupToGeoJSON = (
  pointGroup: MapPointGroup
): FeatureCollection => {
  const points = pointGroup.points;
  const features: Feature[] = [];

  if (points.length > 1 && pointGroup.geometry === 'LineString') {
    const lineCoordinates = points.map((point) => [
      point.longitude,
      point.latitude,
    ]);
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: lineCoordinates,
      },
      properties: {},
    });
  } else if (pointGroup.geometry === 'Point') {
    points.forEach((point) => {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude],
        },
        properties: point.properties || {},
      });
    });
  }

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const addGeoJSONSource = (
  map: mapboxgl.Map,
  group: MapPointGroup
): [[number, number], [number, number]] => {
  const geojsonFeatures = convertPointGroupToGeoJSON(group);

  map.addSource(group.groupId, {
    type: 'geojson',
    data: geojsonFeatures,
  });

  return calculateBoundingBox(geojsonFeatures);
};

export const updateSourceGeoJSON = (
  map: mapboxgl.Map,
  group: MapPointGroup
): [[number, number], [number, number]] => {
  const source = map.getSource(group.groupId);
  if (source && 'setData' in source) {
    const geojsonFeatures = convertPointGroupToGeoJSON(group);
    source.setData(geojsonFeatures);
    return calculateBoundingBox(geojsonFeatures);
  }

  return [
    [Infinity, Infinity],
    [-Infinity, -Infinity],
  ];
};
