import { AxiosInstance, AxiosResponse } from 'axios';
import { genericFetch } from './api';
import { Auth } from '../state/auth/types';
import { get, post } from '../hooks/useAxiosPrivate';
import {
  ArtycSnapshotMode,
  SegmentEntity,
  SegmentSnapshotEntity,
} from '../state/segments/types';

const ARTYC_SEGMENT_URL = '/artyc/segments';
const COMPANY_SEGMENT_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/segments`;

interface GetSegmentMetricResponse {
  metric: string;
}
const SegmentsApi = {
  fetchMetric: async (
    axios: AxiosInstance,
    auth: Auth | null,
    endpoint: string,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    const response: GetSegmentMetricResponse = await genericFetch(
      axios,
      auth,
      `/artyc/segments/metrics/${endpoint}`,
      `/api/v1/company/${forCompanyId}/segments/metrics/${endpoint}`,
      {
        rangeStartDate,
        rangeEndDate,
        companyIds: companyIdsFilter,
      }
    );
    return response.metric;
  },

  getHoursWithoutExcursion: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return SegmentsApi.fetchMetric(
      axios,
      auth,
      'hours-without-excursion',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },

  getMaxDeviceUtilization: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return SegmentsApi.fetchMetric(
      axios,
      auth,
      'max-device-utilization',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },

  getTotalSegments: async (
    axios: AxiosInstance,
    auth: Auth | null,
    rangeStartDate: string | undefined,
    rangeEndDate: string | undefined,
    companyIdsFilter: string[] | undefined,
    forCompanyId: string | undefined
  ): Promise<string> => {
    return SegmentsApi.fetchMetric(
      axios,
      auth,
      'total-segments',
      rangeStartDate,
      rangeEndDate,
      companyIdsFilter,
      forCompanyId
    );
  },
  downloadSegmentCsv: async (
    axios: AxiosInstance,
    auth: Auth | null,
    isArtyc: boolean,
    segmentIds: string[]
  ) => {
    const artycUrl = `${ARTYC_SEGMENT_URL}/csvs`;
    const companyUrl = `${COMPANY_SEGMENT_URL(auth)}/csvs`;
    const entityURL = isArtyc ? artycUrl : companyUrl;

    // TODO: type
    const response: AxiosResponse<any> = await post(
      axios,
      entityURL,
      { segmentIds },
      { responseType: 'blob' }
    );

    return response.data;
  },
  downloadLogCsv: async (
    axios: AxiosInstance,
    isArtyc: boolean,
    segmentIds: string[]
  ) => {
    if (!isArtyc) {
      throw new Error('expecting artyc user');
    }

    const artycUrl = `${ARTYC_SEGMENT_URL}/logs`;
    // can't be used by company atm
    // const companyUrl = `${COMPANY_SEGMENT_URL(auth)}/logs`;
    const entityURL = artycUrl;

    // TODO: type
    const response: AxiosResponse<any> = await post(
      axios,
      entityURL,
      { segmentIds },
      { responseType: 'blob' }
    );

    return response.data;
  },

  getSegmentSnapshots: async (
    axios: AxiosInstance,
    auth: Auth | null,
    isArtyc: boolean,
    segmentId: string,
    artycSnapshotsMode?: ArtycSnapshotMode
  ) => {
    const artycUrl = `${ARTYC_SEGMENT_URL}/${segmentId}/snapshots`;
    const companyUrl = `${COMPANY_SEGMENT_URL(auth)}/${segmentId}/snapshots`;
    const entityURL = isArtyc ? artycUrl : companyUrl;
    const response: AxiosResponse<SegmentSnapshotEntity[]> = await get(
      axios,
      entityURL,
      { mode: artycSnapshotsMode }
    );

    return response.data;
  },
  getSegment: async (
    axios: AxiosInstance,
    auth: Auth | null,
    segmentId: string
  ): Promise<SegmentEntity> => {
    const response: SegmentEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_SEGMENT_URL}/${segmentId}`,
      `${COMPANY_SEGMENT_URL(auth)}/${segmentId}`
    );
    return response;
  },
};

export default SegmentsApi;
