import { Map, Popup } from 'mapbox-gl';
import { GeoJsonProperties } from 'geojson';
import { MapPoint } from '../../../../../components/map/Map';
import { formatGraphTime } from '../../../../../utils/dateUtil';
import './mapPopup.scss';
import { clearMapPopups } from '../../../../../components/map/mapHandlers';

const formatTemperature = (min: number, max: number) => {
  return min === max ? `${min}°C` : `${min}°C - ${max}°C`;
};

export const mkOnHoverHtml =
  (
    segmentId: string,
    isArtycViewer: boolean,
    onHovered: (index: number) => void
  ) =>
  (properties: GeoJsonProperties): string => {
    if (!properties) {
      return '';
    }

    const indexArray: Array<number> = JSON.parse(properties.indexArray);
    onHovered(indexArray[0]);

    const timestampArray: Array<string> = JSON.parse(properties.timestampArray);

    const timestampRanges = [];
    const indexRanges = [];
    let rangeStart = 0;

    for (let i = 0; i < indexArray.length; i++) {
      if (
        i === indexArray.length - 1 ||
        indexArray[i] + 1 !== indexArray[i + 1]
      ) {
        let timeRangeSubString: string;
        let indexRangeSubString: string;
        if (rangeStart === i) {
          timeRangeSubString = `${formatGraphTime(timestampArray[i])}`;
          indexRangeSubString = `${indexArray[i]}`;
        } else {
          timeRangeSubString = `${formatGraphTime(
            timestampArray[rangeStart]
          )} - ${formatGraphTime(timestampArray[i])}`;
          indexRangeSubString = `${indexArray[rangeStart]} - ${indexArray[i]}`;
        }
        timestampRanges.push(timeRangeSubString);
        indexRanges.push(indexRangeSubString);
        rangeStart++;
      }
    }

    const timeRangeString = timestampRanges.join(', ');
    const indexRangeString = indexRanges.join(', ');

    const tempsArray: Array<{ sleeve: number; evap: number; hs: number }> =
      JSON.parse(properties.tempsArray);

    const tempsRanges = tempsArray.reduce(
      (acc, curr) => {
        acc.minSleeve = Math.min(acc.minSleeve, curr.sleeve);
        acc.maxSleeve = Math.max(acc.maxSleeve, curr.sleeve);
        acc.minHs = Math.min(acc.minHs, curr.hs);
        acc.maxHs = Math.max(acc.maxHs, curr.hs);

        return acc;
      },
      {
        minSleeve: Infinity,
        maxSleeve: -Infinity,
        minHs: Infinity,
        maxHs: -Infinity,
      }
    );

    const snapshotCount = indexArray.length;

    // the arrays saved in properties of geoJSON features are getting turned into strings
    // (not entirely sure if is mapbox or something else), so we need to unstringify the array
    const popupContent = `
      <span class="label">Timestamp:</span> <span class="text">${timeRangeString}</span><br>
      <span class="label">${
        isArtycViewer ? 'Heat Sink' : 'Ambient'
      } Temp:</span> <span class="text">${formatTemperature(
      tempsRanges.minHs,
      tempsRanges.maxHs
    )}</span><br>
      <span class="label">${
        isArtycViewer ? 'Sleeve' : 'Internal'
      } Temp:</span> <span class="text">${formatTemperature(
      tempsRanges.minSleeve,
      tempsRanges.maxSleeve
    )}</span><br>
      ${
        isArtycViewer
          ? `
          <span class="label">Segment:</span> <span class="text">${segmentId}</span><br>
          <span class="label">Snapshot Count:</span> <span class="text">${snapshotCount}</span><br>
<span class="label">Indices:</span> <span class="text">${indexRangeString}</span>
          `
          : ''
      }
    `.trim();

    return popupContent;
  };

export const triggerMapPopup = (
  segmentId: string,
  secSinceStart: number,
  isArtycViewer: boolean,
  location: { lat: number; long: number },
  mapPoints: MapPoint[],
  map: Map
) => {
  // find the matching map point
  const matchingPoint = mapPoints.find((point) => {
    const secSinceStartArray = point.properties?.secSinceStartArray;
    return (
      secSinceStartArray &&
      Array.isArray(secSinceStartArray) &&
      secSinceStartArray.includes(secSinceStart)
    );
  });

  // first remove existing popups
  clearMapPopups();

  // create the popup and show it
  if (matchingPoint != undefined && matchingPoint.properties !== null) {
    const properties = {
      ...matchingPoint.properties,
      indexArray: JSON.stringify(matchingPoint.properties.indexArray),
      timestampArray: JSON.stringify(matchingPoint.properties.timestampArray),
      tempsArray: JSON.stringify(matchingPoint.properties.tempsArray),
    };

    const popupContent = mkOnHoverHtml(segmentId, isArtycViewer, () => {})(
      properties
    );

    const scrollPos = window.scrollY;
    map.flyTo({
      center: [location.long, location.lat],
      zoom: Math.max(12, map.getZoom()),
    });

    new Popup()
      .setLngLat([location.long, location.lat])
      .setHTML(popupContent)
      .addTo(map);
    window.scrollTo(0, scrollPos);
  }
};
