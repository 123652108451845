import React, { useState } from 'react';
import Dropdown from '../../../../aurora/components/Filter/Dropdown';
import { Label } from '../../../../aurora/typography/Label/Label';
import { Text } from '../../../../aurora/typography/Text/Text';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import styles from './FleetViewFilter.module.scss';
import { FleetViewSort as SortType } from '../../../../state/fleetView/types';
import { setSort } from '../../../../state/fleetView';
import { IconCheck } from '../../../../aurora/icons';
const FleetViewSort = () => {
  const dispatch = useDispatch();
  const sort = useSelector((state: RootState) => state.fleetView.sort);
  const [open, setOpen] = useState(false);

  const popover = (
    <div className={styles.popover}>
      {Object.entries(SortType).map(([key, value]) => {
        return (
          <div
            key={key}
            className={`${styles.popoverSortItem} ${
              sort === value ? styles.popoverSelectedItem : ''
            }`}
            onClick={() => dispatch(setSort(value))}
          >
            <Text size="sm">{value}</Text>
            {sort === value && <IconCheck width={20} height={20} />}
          </div>
        );
      })}
    </div>
  );

  return (
    <Dropdown
      variant={'filter'}
      text={
        <Label size="sm" color="sub600">
          Sort
        </Label>
      }
      popoverContent={popover}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default FleetViewSort;
