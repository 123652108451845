import React, { useState } from 'react';
import {
  IconHome,
  IconCompassDiscoverFill,
  IconNotification,
  IconCompany,
  IconUser,
  IconShipment,
  IconCube,
  IconCalculator,
  IconFormCheck,
  IconSettings,
  IconSupport,
  IconExit,
  IconCheck,
} from '../../../aurora/icons';
import NavItem, { NavItemProps } from './NavItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { ROLES } from '../../../state/auth/types';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../../state/auth';
import DataModal from '../../DataModal';
import EditSelf from '../../EditSelf';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../../hooks/useLogout';
import { selectIsCaas } from '../../../state/companies';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

interface Props {
  toggleSlideout?: () => void;
}

const NavBarBody = ({ toggleSlideout }: Props) => {
  const { featureFlags } = useFeatureFlags();
  const [showModal, setShowModal] = useState(false);

  const auth = useSelector(selectAuth);
  const navigate = useNavigate();
  const logout = useLogout();

  const isCaas = useSelector(selectIsCaas);
  const canViewShippers = isArtyc(auth) || (isCompanyAdmin(auth) && !isCaas);

  const handleLogout = async () => {
    logout();
    navigate('/login', { replace: true });
  };

  const handleSettingsClick = () => {
    setShowModal(true);
  };

  const renderNavItems = (navItemProps: NavItemProps[]) =>
    navItemProps.map((item, i) => (
      <NavItem
        name={item.name}
        icon={item.icon}
        badgeValue={item.badgeValue}
        onClick={item.onClick}
        toggleSlideout={toggleSlideout}
        key={`nav-item-${i}`}
      />
    ));

  const newEventCount = useSelector(
    (state: RootState) => state.events.newEventCount
  );

  const badgeValue = newEventCount > 0 ? newEventCount.toString() : undefined;
  const { ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE } =
    ROLES;

  let topNavItemProps: NavItemProps[] = [
    {
      name: featureFlags.fleetView ? 'Fleet View' : 'Home',
      icon: featureFlags.fleetView ? IconCompassDiscoverFill : IconHome,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE],
    },
    {
      name: 'Events',
      icon: IconNotification,
      badgeValue,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE],
    },
    {
      name: 'Companies',
      icon: IconCompany,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE],
    },
    {
      name: 'Users',
      icon: IconUser,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN],
    },
    {
      name: 'Journeys',
      icon: IconShipment,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE],
    },
    {
      name: 'Shippers',
      icon: IconCube,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE],
    },
    {
      name: 'Shipping Profiles',
      icon: IconFormCheck,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE],
    },
    {
      name: 'Customer Cost Reports',
      icon: IconCalculator,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE],
    },
    {
      name: 'PCB Tests',
      icon: IconCheck,
      roles: [ARTYC_ADMIN, ARTYC_EMPLOYEE],
    },
  ];

  const bottomNavItemProps: NavItemProps[] = [
    {
      name: 'Settings',
      icon: IconSettings,
      onClick: handleSettingsClick,
    },
    {
      name: 'Support',
      icon: IconSupport,
    },
    {
      name: 'Sign Out',
      icon: IconExit,
      onClick: handleLogout,
    },
  ];

  topNavItemProps = topNavItemProps
    .filter((item) =>
      (item.roles || []).map((role) => role.pid).includes(auth?.role || '')
    )
    .filter((item) => (item.name === 'Shippers' ? canViewShippers : true));

  const onClose = () => setShowModal(false);

  return (
    <>
      <div style={{ flexGrow: '1' }}>{renderNavItems(topNavItemProps)}</div>
      <div>
        {renderNavItems(bottomNavItemProps)}
        <DataModal title="Account Settings" show={showModal} onClose={onClose}>
          <EditSelf onClose={onClose} />
        </DataModal>
      </div>
    </>
  );
};

export default NavBarBody;
