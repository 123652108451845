import React from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../state/auth';
import styles from './DeviceInfoCard.module.scss';
import { DeviceEntity } from '../../../state/devices/types';

interface Tag {
  label: string;
  value: string | number;
  artycOnly?: boolean;
}

interface Props {
  device: DeviceEntity | undefined;
}
const DeviceInfoCard = ({ device }: Props) => {
  if (!device) {
    return null;
  }

  const auth = useSelector(selectAuth);

  const tags: Tag[] = [
    { label: 'Company', value: device.companyName, artycOnly: true },
    { label: 'PCB Number', value: device.pcbNumber, artycOnly: true },
    { label: 'MAC Address', value: device.macAddress, artycOnly: true },
    { label: 'Board Version', value: device.boardVersion, artycOnly: true },
    {
      label: 'Cell Enabled',
      value: device.cellEnabled ? 'Yes' : 'No',
      artycOnly: true,
    },
    ...(device.lastHealthCheck !== undefined && device.lastHealthCheck !== null
      ? [
          {
            label: 'Last Plugged In At',
            value: device.lastHealthCheck.pluggedInAt,
            artycOnly: true,
          },
          {
            label: 'Last Cell Connected',
            value: device.lastHealthCheck.cellConnected ? 'Yes' : 'No',
            artycOnly: true,
          },
          {
            label: 'Last GPS Connected',
            value: device.lastHealthCheck.gpsConnected ? 'Yes' : 'No',
            artycOnly: true,
          },
          {
            label: 'Last Wifi Connected',
            value: device.lastHealthCheck.wifiConnected ? 'Yes' : 'No',
            artycOnly: true,
          },
          {
            label: 'Last Cooling Check',
            value: device.lastHealthCheck.coolingPass ? 'Pass' : 'Fail',
            artycOnly: true,
          },
          {
            label: 'Last Fully Charged',
            value: device.lastHealthCheck.fullyCharged ? 'Yes' : 'No',
            artycOnly: true,
          },
        ]
      : []),
  ];

  return (
    <div className={styles.infoCard}>
      <div className={styles.infoCardHeader}>
        <Text size="lg" className={styles.headingText}>
          {device.deviceType}
        </Text>
        <Text size="sm" className={styles.subheadingText}>
          Serial #{device.serialNumber}
        </Text>
      </div>
      <div className={styles.infoCardBody}>
        {tags
          .filter((tag) => !tag.artycOnly || (tag.artycOnly && isArtyc(auth)))
          .map((tag, idx) => (
            <div key={`tag-${idx}`}>
              <Text size="sm" className={styles.subheadingText}>
                {tag.label}
              </Text>
              <Text size="sm" className={styles.bodyText}>
                {tag.value}
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DeviceInfoCard;
