import { AxiosInstance } from 'axios';
import { genericFetch, genericPatch, genericPost } from './api';
import { Auth } from '../state/auth/types';
import { EventEntity } from '../state/events/types';
import { DataPayload } from '../state/types';

const ARTYC_EVENT_URL = '/artyc/events';
const COMPANY_EVENT_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/events`;

const EventsApi = {
  getNewEventCount: async (
    axios: AxiosInstance,
    auth: Auth | null
  ): Promise<number> => {
    const response = await genericFetch<{ eventCount: number }>(
      axios,
      auth,
      `${ARTYC_EVENT_URL}/new-count`,
      `${COMPANY_EVENT_URL(auth)}/new-count`
    );
    return response.eventCount;
  },
  markReviewed: async (
    axios: AxiosInstance,
    auth: Auth | null,
    eventIds: string[]
  ) => {
    return genericPatch<{ updated: number }>(
      axios,
      auth,
      `${ARTYC_EVENT_URL}/review`,
      `${COMPANY_EVENT_URL(auth)}/review`,
      { eventIds }
    );
  },
  downloadSegmentCsv: async (
    axios: AxiosInstance,
    auth: Auth | null,
    eventIds: string[]
  ) => {
    // TODO: type
    return genericPost<any>(
      axios,
      auth,
      `${ARTYC_EVENT_URL}/csvs`,
      `${COMPANY_EVENT_URL(auth)}/csvs`,
      { eventIds },
      { responseType: 'blob' }
    );
  },
  getEventsForSegment: async (
    axios: AxiosInstance,
    auth: Auth | null,
    segmentId: string
  ): Promise<EventEntity[]> => {
    const { entities } = await genericFetch<DataPayload<EventEntity>>(
      axios,
      auth,
      ARTYC_EVENT_URL,
      COMPANY_EVENT_URL(auth),
      {
        filters: { segmentId },
        paginate: 'false',
      }
    );

    return entities;
  },
  getEvent: async (
    axios: AxiosInstance,
    auth: Auth | null,
    eventId: string
  ): Promise<EventEntity> => {
    const response: EventEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_EVENT_URL}/event/${eventId}`,
      `${COMPANY_EVENT_URL(auth)}/event/${eventId}`
    );
    return response;
  },
};

export default EventsApi;
