import React, { useMemo, useRef, useState, useEffect } from 'react';
import { GeoJsonProperties } from 'geojson';
import Map, { MapPointGroup } from '../../../../components/map/Map';
import styles from '../../FleetViewPage.module.scss';
import { mkPointGroups } from './mkPointGroups';
import mapboxgl from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { setOpenIdx } from '../../../../state/fleetView/';
import { MappedDevice } from '../../../../state/fleetView/types';

interface FleetViewMapProps {
  mappedDevices: MappedDevice[];
}

const FleetViewMap = ({ mappedDevices }: FleetViewMapProps) => {
  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [zoom, setZoom] = useState<number>(2);

  useEffect(() => {
    if (map) {
      // we want to update the clustering when the zoom level changes
      map.on('zoom', () => {
        setZoom(map.getZoom());
      });
    }
  }, [map]);

  const handlePointClick = (properties: GeoJsonProperties) => {
    if (properties?.index !== undefined) {
      dispatch(setOpenIdx(properties.index));
    }
  };

  const pointGroups: MapPointGroup[] = useMemo(() => {
    return mkPointGroups(mappedDevices, map, handlePointClick);
  }, [mappedDevices, map, zoom]);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Map
      pointGroups={pointGroups}
      ref={mapContainerRef}
      className={styles.customMap}
      onMapLoad={setMap}
    />
  );
};

export default FleetViewMap;
