export enum SegmentStatus {
  PRECOOLING = 'PRECOOLING',
  READY_TO_SHIP = 'READY_TO_SHIP',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export type SnapshotMode = 'all' | 'artyc' | 'customer';
export type ArtycSnapshotMode = Omit<SnapshotMode, 'customer'>;

export interface SegmentEntity {
  _id: string;
  createdAt: string;
  journey: string;
  status: SegmentStatus;
  startTime: string;
  endTime: string | null;
  lengthSec: number | null;
  firstSync?: object | null;
  hasGeoData: boolean;
  deviceType: string;
  serialNumber: string;
  companyName: string;
  firmwareVersion: string;
  setTemperature: number;
  logIncrement: number;
  shippingProfile?: string;
  destinations?: string[];
  destinationConfirmations?: string[];
}

export interface SegmentSnapshotEntity {
  timestamp: string;
  secSinceStart: number;
  temps: {
    sleeve: number;
    evap: number;
    hs: number;
  };
  location: {
    lat: number;
    long: number;
  } | null;
  soc: number;
  outputV: string;
  lidOpen: boolean | null;
  createdAt: string;
}
