import React from 'react';
import { useDispatch } from 'react-redux';
import TextInput from '../../../../aurora/components/TextInput/TextInput';
import { IconSearch } from '../../../../aurora/icons';
import { setSearchQuery } from '../../../../state/fleetView';
import styles from './DetailsHeader.module.scss';
import FleetViewFilter from './FleetViewFilter';
import FleetViewSort from './FleetViewSort';

const DetailsHeader = () => {
  const dispatch = useDispatch();

  const handleSearch = (value: string) => {
    if (value === '') {
      dispatch(setSearchQuery(undefined));
    } else {
      dispatch(setSearchQuery(value));
    }
  };

  return (
    <div className={styles.detailsHeader}>
      <TextInput
        variant="filter"
        placeholder={'Search'}
        iconLeft={IconSearch}
        onChange={handleSearch}
        className={styles.searchInput}
      />
      <FleetViewFilter />
      <FleetViewSort />
    </div>
  );
};

export default DetailsHeader;
