import React from 'react';
import { SegmentEntity } from '../../../../../state/segments/types';
import SnapshotsMap from './SnapshotsMap';
import { useSelector } from 'react-redux';
import { selectSnapshotsBySegment } from '../../../../../state/segments';
import InfoCard from '../InfoCard';
import { ShipmentEntity } from '../../../../../state/shipments/types';

interface Props {
  segment: SegmentEntity;
  shipment: ShipmentEntity;
  isArtycViewer: boolean;
}
const SnapshotsMapContainer = ({ segment, shipment, isArtycViewer }: Props) => {
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);

  const hasGeoData = segment.hasGeoData;
  if (!hasGeoData) {
    return null;
  }

  return (
    <InfoCard title="Location Tracking">
      <SnapshotsMap
        segment={segment}
        shipment={shipment}
        snapshotsBySegment={snapshotsBySegment}
        isArtycViewer={isArtycViewer}
      />
    </InfoCard>
  );
};

export default SnapshotsMapContainer;
