import React from 'react';
import {
  Accordion as BaseAccordion,
  AccordionItem,
} from '@szhsin/react-accordion';
import { ReactNode } from 'react';
import { IconChevronDown } from '../../icons';
import styles from './style.module.scss';
import { useSx } from 'dripsy';
import { Label } from '../../typography/Label/Label';

export interface AccordionSection {
  header: ReactNode;
  body: ReactNode;
  tableSection?: boolean;
}

interface Props {
  sections: AccordionSection[];
  initialOpenIdx?: number;
  className?: string;
  onAccordionToggle?: (idx: number, open: boolean) => void;
  panelClassName?: string;
}
export const Accordion = ({
  sections,
  initialOpenIdx,
  className,
  onAccordionToggle,
  panelClassName,
}: Props) => {
  const sx = useSx();

  return (
    <BaseAccordion
      onStateChange={({ key, current }) => {
        if (current.isResolved && onAccordionToggle) {
          onAccordionToggle(parseInt(key as string), current.isEnter);
        }
      }}
    >
      {sections.map((section, i) => (
        <AccordionItem
          data-accordion-id={`accordion-${i}`}
          initialEntered={
            initialOpenIdx !== undefined ? initialOpenIdx === i : undefined
          }
          header={
            <>
              <Label size="sm" color="strong900">
                {section.header}
              </Label>
              <IconChevronDown
                className={styles.chevron}
                height={20}
                width={20}
              />
            </>
          }
          className={`${styles.item} ${className}`}
          style={sx({ color: 'gray600v1', borderColor: 'neutral700' })}
          buttonProps={{
            className: ({ isEnter }) =>
              `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
          }}
          contentProps={{ className: styles.itemContent }}
          panelProps={{
            className: `${
              section.tableSection ? styles.itemTablePanel : styles.itemPanel
            } ${panelClassName || ''}`,
            style: sx({ borderColor: 'neutral700' }),
          }}
          key={`accordion-${i}`}
          itemKey={i}
        >
          {section.body}
        </AccordionItem>
      ))}
    </BaseAccordion>
  );
};
