import React, { useState } from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  DeviceEntity,
  ShipperEnvSwitchStatus,
} from '../../state/devices/types';
import {
  createLoadDevices,
  resetPage,
  setCurrentPage,
  sortDevices,
} from '../../state/devices';
import PageHeader from '../../components/layout/PageHeader';
import {
  ColumnsConfig,
  EmptyConfig,
  SlideoutConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { IconCube } from '../../aurora/icons';
import { isArtyc, isArtycAdmin, selectAuth } from '../../state/auth';
// import AddDeviceButton from './components/AddDeviceButton';
import CompanyFilter from '../../components/filters/CompanyFilter/CompanyFilter';
import DeviceTypeFilter from '../../components/filters/DeviceTypeFilter/DeviceTypeFilter';
import { setDeviceTypesFilter, setCompanyIdsFilter } from '../../state/devices';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import DeviceSearch from './components/DeviceSearch';
import styles from '../page.module.scss';
import MobileCompanyDeviceFilter from '../../components/filters/MobileChecklistFilter/MobileCompanyDeviceFilter';
import Switch from '../../aurora/components/Switch/Switch';
import StatusBadge from '../../aurora/components/StatusBadge/StatusBadge';
import DevicesBulkAction from './components/DevicesBulkAction';
import DevicesTableAction from './components/DevicesTableAction';
import Badge, { BadgeStyle } from '../../aurora/components/Badge/Badge';
import DeviceSlideout from './components/DeviceSlideout';
import { capitalize } from '../../utils/stringUtil';

const DevicesPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const { deviceTypes, companyIds } = useSelector(
    (state: RootState) => state.devices.selectedFilters || {}
  );

  const [showEnvironments, setShowEnvironments] = useState(false);

  const handleEnvironmentSwitch = () => {
    setShowEnvironments(!showEnvironments);
  };

  const loadDevices = createLoadDevices(showEnvironments);

  const topConfig: TopConfig = {
    // right now we only filter on these for artyc admin
    additionalBars: [
      <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <DeviceSearch />
          {isArtycAdmin(auth) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '2px',
                gap: '8px',
              }}
            >
              <Switch
                isSwitchOn={showEnvironments}
                onChange={handleEnvironmentSwitch}
              />
              <span>All Environments</span>
            </div>
          )}
        </div>
        <div className={styles.filterGroup}>
          <DeviceTypeFilter
            setDeviceTypesAction={setDeviceTypesFilter}
            filteredDeviceTypes={deviceTypes}
          />
          {isArtyc(auth) && (
            <CompanyFilter
              setCompanyIdsAction={(companyIds) =>
                dispatch(setCompanyIdsFilter(companyIds))
              }
              filteredCompanyIds={companyIds}
            />
          )}
          <MobileCompanyDeviceFilter
            setDeviceTypesAction={(deviceTypes) =>
              dispatch(setDeviceTypesFilter(deviceTypes))
            }
            filteredDeviceTypes={deviceTypes}
            setCompanyIdsAction={(companyIds) =>
              dispatch(setCompanyIdsFilter(companyIds))
            }
            filteredCompanyIds={companyIds}
          />
        </div>
      </>,
    ],
  };

  const emptyConfig: EmptyConfig = {
    icon: IconCube,
    title: 'No shippers found',
    body: "Once shippers are detected, they'll show up here",
  };
  // TODO(test): test that company only shows for artyc admin
  const columnsConfig: ColumnsConfig<DeviceEntity> = {
    columns: [
      {
        title: 'Shipper Model',
        property: 'deviceType',
      },
      {
        title: 'Serial #',
        property: 'serialNumber',
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtyc(auth),
      },
      {
        title: 'Firmware Version',
        property: 'firmwareVersion',
        showColumn: isArtyc(auth),
      },
      {
        title: 'Ready Status',
        property: 'lastHealthCheck',
        showColumn: isArtyc(auth),
        disableSort: true,
        customComponent: (_, entity) => {
          if (
            entity.lastHealthCheck === undefined ||
            entity.lastHealthCheck === null
          ) {
            return (
              <Badge content="No Test" color="gray" style={BadgeStyle.Filled} />
            );
          }

          const cellReady =
            !entity.cellEnabled || entity.lastHealthCheck.cellConnected;
          const ready =
            entity.lastHealthCheck.coolingPass &&
            entity.lastHealthCheck.fullyCharged &&
            cellReady;
          if (ready) {
            return (
              <Badge content="Ready" color="green" style={BadgeStyle.Filled} />
            );
          }
          return (
            <Badge content="Not Ready" color="red" style={BadgeStyle.Filled} />
          );
        },
      },
      {
        title: 'Environment',
        property: 'currentEnvironment',
        showColumn: isArtycAdmin(auth) && showEnvironments,
        customComponent: (data, entity) => {
          const isSwitching =
            entity?.envSwitchStatus !== ShipperEnvSwitchStatus.SWITCH_COMPLETE;
          return (
            <StatusBadge
              content={capitalize(data as string)}
              status={isSwitching ? 'gray' : 'green'}
            />
          );
        },
      },
    ],
    actionColumn: (entity: DeviceEntity) => (
      <DevicesTableAction
        device={entity}
        loadDevices={loadDevices}
        showEnvironments={showEnvironments}
      />
    ),
    bulkAction:
      isArtycAdmin(auth) && showEnvironments
        ? (devices: DeviceEntity[]) => (
            <DevicesBulkAction devices={devices} loadDevices={loadDevices} />
          )
        : undefined,
  };

  const slideoutConfig: SlideoutConfig<DeviceEntity> = {
    slideout: (device, open, closeSlideout) => (
      <DeviceSlideout
        device={device}
        open={device ? open : false}
        closeSlideout={closeSlideout}
      />
    ),
  };

  return (
    <>
      <PageHeader
        headingText="Shippers"
        // action={<AddDeviceButton loadDevices={loadDevices} />}
      />
      <DataTableContainer
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        slideoutConfig={isArtyc(auth) ? slideoutConfig : undefined}
        sortAction={sortDevices}
        loadDataAction={loadDevices}
        pageAction={setCurrentPage}
        stateName="devices"
        resetAction={resetPage}
      />
    </>
  );
};

export default DevicesPage;
