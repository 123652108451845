import React from 'react';
import styles from './FleetViewMapOverlay.module.scss';
import { IconMapDot } from '../../../../aurora/icons';
import {
  FleetViewDevice,
  FleetViewStatus,
} from '../../../../state/fleetView/types';

interface Props {
  devices: FleetViewDevice[];
}

const FleetViewMapOverlay = ({ devices }: Props) => {
  const deployedCount = devices.filter(
    (device) => device.deviceDeploymentStatus === FleetViewStatus.DEPLOYED
  ).length;

  const standbyCount = devices.filter(
    (device) => device.deviceDeploymentStatus === FleetViewStatus.ON_STANDBY
  ).length;

  return (
    <div className={styles.overlay}>
      <div className={styles.countItem}>
        <div className={styles.deployedIcon}>
          <IconMapDot width={16} height={16} />
        </div>
        <div>Deployed {`(${deployedCount})`}</div>
      </div>
      <div className={styles.countItem}>
        <div className={styles.standbyIcon}>
          <IconMapDot width={16} height={16} />
        </div>
        <div>On Standby {`(${standbyCount})`}</div>
      </div>
    </div>
  );
};

export default FleetViewMapOverlay;
