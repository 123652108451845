import React from 'react';
import FleetViewPopup from '../../pages/fleetView/components/map/FleetViewPopup';
import { createRoot } from 'react-dom/client';
import { AuroraProvider } from '../../aurora/theme/AuroraProvider';
import { PointProperties } from '../../pages/fleetView/components/map/mkPointGroups';

export const renderPopup = (properties: PointProperties): HTMLDivElement => {
  const popupNode = document.createElement('div');
  const root = createRoot(popupNode);
  root.render(
    <AuroraProvider>
      <FleetViewPopup properties={properties} />
    </AuroraProvider>
  );
  return popupNode;
};
