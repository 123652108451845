import mapboxgl from 'mapbox-gl';
import { CirclePaint } from 'mapbox-gl';
import { MapPointGroup } from './Map';
import { renderPopup } from './renderPopup';
import { PointProperties } from '../../pages/fleetView/components/map/mkPointGroups';

export const handleMouseEnter = (
  map: mapboxgl.Map,
  e: mapboxgl.MapMouseEvent,
  popup: mapboxgl.Popup,
  onHover: MapPointGroup['onHover']
) => {
  if (e.features === undefined || e.features.length === 0) {
    return;
  }

  const feature = e.features[0];

  const coordinates =
    feature.geometry.type === 'Point'
      ? (feature.geometry.coordinates as [number, number])
      : ([e.lngLat.lng, e.lngLat.lat] as [number, number]);

  if (onHover?.html) {
    const popupNode = renderPopup(feature.properties as PointProperties);
    map.getCanvas().style.cursor = 'pointer';
    popup.setLngLat(coordinates).setDOMContent(popupNode).addTo(map);
  }

  if (onHover?.paint) {
    const layerId = feature.layer.id;
    const { hoverPaint, defaultPaint, conditionalPaintCase } = onHover.paint;
    if (hoverPaint && defaultPaint) {
      Object.entries(hoverPaint).forEach(([hoverProperty, hoverValue]) => {
        map.setPaintProperty(
          layerId,
          hoverProperty,
          conditionalPaintCase
            ? [
                'case',
                conditionalPaintCase(feature.properties),
                hoverValue,
                defaultPaint[hoverProperty as keyof CirclePaint],
              ]
            : hoverValue
        );
      });
    }
  }
};

export const handleMouseLeave = (
  map: mapboxgl.Map,
  popup: mapboxgl.Popup,
  layerId: string,
  defaultPaint?: CirclePaint
) => {
  map.getCanvas().style.cursor = 'default';
  popup.remove();
  if (defaultPaint) {
    Object.entries(defaultPaint).forEach(([property, value]) => {
      map.setPaintProperty(layerId, property, value);
    });
  }
};

export const clearMapPopups = () => {
  const popups = document.getElementsByClassName('mapboxgl-popup');
  Array.from(popups).forEach((popup) => popup.remove());
};
