import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetViewDevice, FleetViewFilter, FleetViewSort } from './types';

interface FleetViewState {
  openIdx: number | null;
  selectedCompany: string | null;
  devices: FleetViewDevice[];
  searchQuery?: string;
  filter: { [K in keyof typeof FleetViewFilter]: boolean };
  sort: FleetViewSort;
}

const initialState: FleetViewState = {
  openIdx: null,
  selectedCompany: null,
  devices: [],
  filter: Object.fromEntries(
    Object.keys(FleetViewFilter).map((key) => [key, true])
  ) as { [K in keyof typeof FleetViewFilter]: boolean },
  sort: FleetViewSort.UPDATED_DESC,
};

const fleetViewSlice = createSlice({
  name: 'fleetView',
  initialState,
  reducers: {
    setOpenIdx(state, action: PayloadAction<number | null>) {
      state.openIdx = action.payload;
    },
    setSelectedCompany(state, action: PayloadAction<string | null>) {
      state.selectedCompany = action.payload;
    },
    setDevices(state, action: PayloadAction<FleetViewDevice[]>) {
      state.devices = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string | undefined>) {
      state.searchQuery = action.payload;
    },
    toggleFilter(
      state,
      action: PayloadAction<{ filter: keyof typeof FleetViewFilter }>
    ) {
      const newFilter = {
        ...state.filter,
        [action.payload.filter]: !state.filter[action.payload.filter],
      };
      state.filter = newFilter;
    },
    setSort(state, action: PayloadAction<FleetViewSort>) {
      state.sort = action.payload;
    },
  },
});

export const {
  setOpenIdx,
  setSelectedCompany,
  setDevices,
  setSearchQuery,
  toggleFilter,
  setSort,
} = fleetViewSlice.actions;
export default fleetViewSlice.reducer;
