import { cToF, toDecimalPlaces } from '../../../../../utils/tempUtil';
import { externalTooltipHandler } from './Tooltip';
import { ChartOptions } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { SegmentSnapshotEntity } from '../../../../../state/segments/types';
import {
  formatDateAsDuration,
  formatGraphTime,
} from '../../../../../utils/dateUtil';
import { TimeRange } from './SnapshotsGraphContainer';

export const formatXAxisValue = (
  value: string | number,
  xAxisType: 'time' | 'duration' | 'timeDuration',
  startDate?: Date
): string => {
  if (xAxisType === 'timeDuration') {
    return formatDateAsDuration(value, startDate);
  }
  if (xAxisType === 'time') {
    return formatGraphTime(value);
  }
  return value.toString();
};

export const convertTemp = (
  tempUnit: 'C' | 'F'
): ((temp: number) => number) => {
  return tempUnit === 'F'
    ? (temp: number) => toDecimalPlaces(cToF(temp), 2)
    : (temp: number) => toDecimalPlaces(temp, 2);
};

export const mkDatapoint = (snapshot: SegmentSnapshotEntity, y: number) => ({
  x: new Date(snapshot.timestamp).valueOf(),
  y,
});

// TODO: this is getting quite messy that we're using the same chart options when there's different x axes
export const mkChartOptions = (
  xAxisType: 'time' | 'duration' | 'timeDuration',
  tempUnit: 'C' | 'F',
  tempThresholdsC?: number[],
  lidOpens?: { start: number; end: number }[],
  minTempAxisC?: number,
  maxTempAxisC?: number,
  startDate?: Date,
  xAxisRange?: TimeRange,
  onHover?: (index: number) => void
): ChartOptions<'line'> => {
  const annotations: AnnotationOptions[] = [];
  if (tempThresholdsC && tempThresholdsC.length > 0) {
    tempThresholdsC.forEach((temp) => {
      annotations.push({
        type: 'line',
        yMin: convertTemp(tempUnit)(temp),
        yMax: convertTemp(tempUnit)(temp),
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
      });
    });
  }

  if (lidOpens && lidOpens.length > 0) {
    lidOpens.forEach((lidOpen) => {
      annotations.push({
        type: 'box',
        xMin: lidOpen.start,
        xMax: lidOpen.end,
        backgroundColor: 'rgba(245, 191, 1, 0.25)',
        borderColor: 'rgba(252, 188, 7, 0.25)',
        borderWidth: 2,
      });
    });
  }

  return {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context) =>
          externalTooltipHandler(context, xAxisType, startDate),
      },
      annotation: {
        annotations,
      },
    },
    onHover: (event, activeElements) => {
      if (onHover !== undefined && activeElements.length > 0) {
        onHover(activeElements[0].index);
      }
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index) => {
            return index % 3 === 0
              ? formatXAxisValue(value, xAxisType, startDate)
              : '';
          },
        },
        type: 'time',
        time: {
          unit: 'minute',
        },
        min: xAxisRange?.startMs,
        max: xAxisRange?.endMs,
      },
      temperature: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: minTempAxisC && convertTemp(tempUnit)(minTempAxisC),
        max: maxTempAxisC && convertTemp(tempUnit)(maxTempAxisC),
      },
      voltage: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        min: 0,
      },
    },
  };
};
