import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { SegmentToSnapshotsMap } from '../../../../../state/segments';
import Map, { MapAsset } from '../../../../../components/map/Map';
import { groupSnapshotsByPoints, mkPointGroups } from './mapUtils';
import { SegmentEntity } from '../../../../../state/segments/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import mapboxPinImage from './MapboxPin.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { setHoveredSnapshot } from '../../../../../state/journeys';
import { Map as MapboxMap } from 'mapbox-gl';
import { triggerMapPopup } from './mapHandler';
import MapLegend from './MapLegend';
import { isValidLatLong } from '../../../../../components/map/mapBounds';
import { clearMapPopups } from '../../../../../components/map/mapHandlers';
import { Text } from '../../../../../aurora/typography/Text/Text';
import styles from './SnapshotsMap.module.scss';

export interface SnapshotsMapProps {
  segment: SegmentEntity;
  shipment: ShipmentEntity;
  snapshotsBySegment: SegmentToSnapshotsMap;
  isArtycViewer: boolean;
}

const SnapshotsMap = ({
  segment,
  shipment,
  snapshotsBySegment,
  isArtycViewer,
}: SnapshotsMapProps) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapboxMap | null>(null);
  const dispatch = useDispatch();
  const [noGps, setNoGps] = useState(false);

  const { hoveredSnapshot } = useSelector((state: RootState) => state.journeys);
  const snapshots = snapshotsBySegment[segment._id];
  // memoize so it doesn't reload map on every hover
  const mapPoints = useMemo(
    () => groupSnapshotsByPoints(snapshots),
    [snapshots]
  );

  const onHovered = useCallback(
    (index: number) => {
      const snapshot = snapshots[index];
      if (
        hoveredSnapshot === null ||
        hoveredSnapshot.snapshot.secSinceStart !== snapshot.secSinceStart
      ) {
        dispatch(
          setHoveredSnapshot({
            hovered: { snapshot, trigger: 'map', index },
          })
        );
      }
    },
    [hoveredSnapshot]
  );

  useEffect(() => {
    setNoGps(false);
    if (hoveredSnapshot !== null && hoveredSnapshot.trigger !== 'map') {
      const location = hoveredSnapshot.snapshot.location;

      if (location !== null && isValidLatLong(location.lat, location.long)) {
        if (mapRef.current !== null) {
          triggerMapPopup(
            segment._id,
            hoveredSnapshot.snapshot.secSinceStart,
            isArtycViewer,
            location,
            mapPoints,
            mapRef.current
          );
        }
      } else {
        setNoGps(true);
        clearMapPopups();
        setTimeout(() => {
          setNoGps(false);
        }, 3000);
      }
    }
  }, [hoveredSnapshot, mapPoints, segment._id, isArtycViewer]);

  const pointGroups = useMemo(
    () =>
      mkPointGroups(
        segment,
        shipment,
        snapshots,
        mapPoints,
        isArtycViewer,
        onHovered
      ),
    [segment, shipment, snapshots, mapPoints, isArtycViewer]
  );

  const mapAssets: MapAsset[] = useMemo(
    () => [
      {
        name: 'MapboxPin',
        path: mapboxPinImage,
      },
    ],
    []
  );

  const handleMapLoad = useCallback((map: MapboxMap) => {
    mapRef.current = map;
  }, []);

  return (
    <div className={styles.mapContainer}>
      {noGps && (
        <div className={styles.noGps}>
          <Text size="xs">There is no GPS data for this timestamp</Text>
        </div>
      )}
      <Map
        pointGroups={pointGroups}
        assets={mapAssets}
        ref={mapContainerRef}
        onMapLoad={handleMapLoad}
      />
      <MapLegend segment={segment} shipment={shipment} map={mapRef} />
    </div>
  );
};

export default SnapshotsMap;
