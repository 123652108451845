import { genericFetch, genericPatch, genericPost } from './api';
import { Auth } from '../state/auth/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { JourneyEntity } from '../state/journeys/types';
import {
  ShipmentEntity,
  ShipmentTypeEnum,
  ShippoCarrierEnum,
} from '../state/shipments/types';
import {
  AddressEntity,
  NewOrExistingAddressEntity,
} from '../state/addresses/types';

const ARTYC_JOURNEY_URL = '/artyc/journeys';
const COMPANY_JOURNEY_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/journeys`;

export interface ShipmentBody {
  leg: number;
  shipmentType: ShipmentTypeEnum;
  carrier?: ShippoCarrierEnum;
  trackingNumber?: string;
  origin?: NewOrExistingAddressEntity;
  destination?: NewOrExistingAddressEntity;
}

export interface CreateJourneyRequestBody {
  company: string;
  shippingProfile: string;
  expectedStartDate?: string;
  poNumber?: string;
  customId?: string;
  device?: string;
  shipments: ShipmentBody[];
}

// assume we're only ever updating the destination address
// for now frontend can't set lat/long, but it may in the future
export type UpdateJourneyShipmentBody = Omit<
  ShipmentBody,
  'leg' | 'shipmentType' | 'origin' | 'destination'
> & {
  destination: Omit<
    AddressEntity,
    'country' | 'addressType' | 'latitude' | 'longitude'
  >;
};

export interface UpdateJourneyRequestBody {
  expectedStartDate?: string;
  device?: string;
  shipments: {
    [shipmentId: string]: UpdateJourneyShipmentBody;
  };
}

const JourneysApi = {
  getJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyId: string
  ): Promise<JourneyEntity> => {
    const response: JourneyEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_JOURNEY_URL}/${journeyId}`,
      `${COMPANY_JOURNEY_URL(auth)}/${journeyId}`
    );
    return response;
  },
  createJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyData: CreateJourneyRequestBody
  ): Promise<any> => {
    const response = await genericPost(
      axios,
      auth,
      ARTYC_JOURNEY_URL,
      COMPANY_JOURNEY_URL(auth),
      journeyData
    );

    return response;
  },
  getShipmentsForJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyId: string
  ): Promise<ShipmentEntity[]> => {
    const response: ShipmentEntity[] = await genericFetch(
      axios,
      auth,
      `${ARTYC_JOURNEY_URL}/${journeyId}/shipments`,
      `${COMPANY_JOURNEY_URL(auth)}/${journeyId}/shipments`
    );
    return response;
  },
  updateJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyId: string,
    journeyUpdate: UpdateJourneyRequestBody
  ): Promise<{ success: string }> => {
    const response: AxiosResponse<{ success: string }> = await genericPatch(
      axios,
      auth,
      `${ARTYC_JOURNEY_URL}/${journeyId}`,
      `${COMPANY_JOURNEY_URL(auth)}/${journeyId}`,
      journeyUpdate
    );
    return response.data;
  },
};

export default JourneysApi;
