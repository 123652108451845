import { MapPoint, MapPointGroup } from '../../../../../components/map/Map';
import { isValidLatLong } from '../../../../../components/map/mapBounds';
import {
  SegmentEntity,
  SegmentSnapshotEntity,
  SegmentStatus,
} from '../../../../../state/segments/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import { mkOnHoverHtml } from './mapHandler';

export const groupSnapshotsByPoints = (
  snapshots: SegmentSnapshotEntity[]
): MapPoint[] => {
  const pointsMap: { [key: string]: MapPoint } = {};
  let currentPointIndex = 0;

  snapshots.forEach((snapshot, index) => {
    const { location, timestamp, secSinceStart, temps } = snapshot;

    if (location && isValidLatLong(location.lat, location.long)) {
      // TODO: update this logic. It currently groups all snapshots that
      // share a location, but it should only group consecutive snapshots
      // that share a location.
      const coordinates: [number, number] = [location.long, location.lat];
      const coordKey = coordinates.join(',');

      if (!pointsMap[coordKey]) {
        pointsMap[coordKey] = {
          latitude: location.lat,
          longitude: location.long,
          properties: {
            pointIndex: currentPointIndex++,
            timestampArray: [timestamp],
            secSinceStartArray: [secSinceStart],
            tempsArray: [temps],
            indexArray: [index],
          },
        };
      } else {
        pointsMap[coordKey].properties!.timestampArray.push(timestamp);
        pointsMap[coordKey].properties!.tempsArray.push(temps);
        pointsMap[coordKey].properties!.secSinceStartArray.push(secSinceStart);
        pointsMap[coordKey].properties!.indexArray.push(index);
      }
    }
  });

  return Object.values(pointsMap).sort(
    (a, b) => (a.properties?.pointIndex ?? 0) - (b.properties?.pointIndex ?? 0)
  );
};

const mkPointsAndLines = (
  segmentId: string,
  mapPoints: MapPoint[],
  isArtycViewer: boolean,
  onHovered: (index: number) => void
): MapPointGroup[] => {
  return [
    {
      groupId: `lines${segmentId}`,
      geometry: 'LineString',
      points: mapPoints,
      groupStyle: {
        id: `lines${segmentId}`,
        type: 'line',
        source: `lines${segmentId}`,
        paint: {
          'line-color': '#2970ff',
          'line-width': 2,
        },
      },
    },
    {
      groupId: `points${segmentId}`,
      geometry: 'Point',
      points: mapPoints,
      groupStyle: {
        id: `points${segmentId}`,
        type: 'circle',
        source: `points${segmentId}`,
        paint: {
          'circle-radius': 4,
          'circle-color': '#2970ff',
        },
      },
      onHover: {
        html: mkOnHoverHtml(segmentId, isArtycViewer, (index: number) =>
          onHovered(index)
        ),
      },
    },
  ];
};

const mkMarkerPoint = (
  latlong: LatLong,
  id: string,
  backgroundColor: string
): MapPointGroup[] => {
  if (latlong === undefined) {
    return [];
  }

  const point = {
    latitude: latlong[0],
    longitude: latlong[1],
    properties: {},
  };

  const backgroundLayer: MapPointGroup = {
    groupId: `${id}-bg`,
    geometry: 'Point',
    points: [point],
    groupStyle: {
      id: `${id}-bg`,
      type: 'circle',
      source: `${id}-bg`,
      paint: {
        'circle-radius': 12,
        'circle-color': backgroundColor,
        'circle-radius-transition': { duration: 0 },
      },
    },
  };

  const pinLayer: MapPointGroup = {
    groupId: id,
    geometry: 'Point',
    points: [point],
    groupStyle: {
      id: id,
      type: 'symbol',
      source: id,
      layout: {
        'icon-image': 'MapboxPin',
        'icon-size': 1,
      },
    },
  };

  return [backgroundLayer, pinLayer];
};

type LatLong = [number, number] | undefined;
type MarkerPoints = { start: LatLong; here: LatLong; end: LatLong };
const mkMarkerPoints = (markerPoints: MarkerPoints): MapPointGroup[] => {
  const { start, here, end } = markerPoints;
  const startPoint = start ? mkMarkerPoint(start, 'start', '#2970ff') : [];
  const endPoint = end ? mkMarkerPoint(end, 'end', '#1FC16B') : [];

  const herePoint: MapPointGroup[] = here
    ? [
        {
          groupId: 'here',
          geometry: 'Point',
          points: [
            {
              latitude: here[0],
              longitude: here[1],
              properties: {},
            },
          ],
          groupStyle: {
            id: `here`,
            type: 'circle',
            source: `here`,
            paint: {
              'circle-radius': 4,
              'circle-color': '#f5f7fa',
              'circle-stroke-color': '#181B25',
              'circle-stroke-width': 1,
            },
          },
        },
      ]
    : [];

  return [...startPoint, ...herePoint, ...endPoint];
};

export const calculateMarkerPoints = (
  segment: SegmentEntity,
  shipment: ShipmentEntity,
  snapshots: SegmentSnapshotEntity[]
): MarkerPoints => {
  const firstSnapshotLocation = snapshots.find(
    (s) =>
      s.location !== null && isValidLatLong(s.location.lat, s.location.long)
  )?.location;
  const lastSnapshotLocation = [...snapshots]
    .reverse()
    .find(
      (s) =>
        s.location !== null && isValidLatLong(s.location.lat, s.location.long)
    )?.location;

  let start: LatLong;
  let here: LatLong;
  let end: LatLong;

  // start is origin or first snapshot
  if (
    shipment.origin &&
    shipment.origin.latitude &&
    shipment.origin.longitude
  ) {
    start = [shipment.origin.latitude, shipment.origin.longitude];
  } else if (firstSnapshotLocation) {
    start = [firstSnapshotLocation.lat, firstSnapshotLocation.long];
  } else {
    start = undefined;
  }

  // end is destination or if ended, last snapshot
  if (
    shipment.destination &&
    shipment.destination.latitude &&
    shipment.destination.longitude
  ) {
    end = [shipment.destination.latitude, shipment.destination.longitude];
  } else if (segment.status === SegmentStatus.COMPLETE) {
    end = lastSnapshotLocation
      ? [lastSnapshotLocation.lat, lastSnapshotLocation.long]
      : undefined;
  }

  // we only want to show here if segment's not over yet
  if (segment.status !== SegmentStatus.COMPLETE) {
    here = lastSnapshotLocation
      ? [lastSnapshotLocation.lat, lastSnapshotLocation.long]
      : undefined;
  }

  return { start, here, end };
};

export const mkPointGroups = (
  segment: SegmentEntity,
  shipment: ShipmentEntity,
  snapshots: SegmentSnapshotEntity[],
  mapPoints: MapPoint[],
  isArtycViewer: boolean,
  onHovered: (index: number) => void
): MapPointGroup[] => {
  const pointsAndLines = mkPointsAndLines(
    segment._id,
    mapPoints,
    isArtycViewer,
    onHovered
  );

  const markerPoints = calculateMarkerPoints(segment, shipment, snapshots);

  return [...pointsAndLines, ...mkMarkerPoints(markerPoints)];
};
