import React from 'react';

import { IconBase, IconProps } from './IconBase';

export const IconMapDot: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="6.5" fill="currentColor" />
  </IconBase>
);
