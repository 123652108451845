import './App.scss';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import RequireAuth from './components/RequireAuth';
import useRefreshToken from './hooks/useRefreshToken';
import useFeatureFlags from './hooks/useFeatureFlags';
import useAxiosPrivate from './hooks/useAxiosPrivate';

import { ROLES } from './state/auth/types';
const { ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN, COMPANY_EMPLOYEE } = ROLES;

import Unauthorized from './pages/Unauthorized';
import Missing from './pages/Missing';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import RegisterConfirmationPage from './pages/auth/RegisterConfirmationPage';
import PasswordResetConfirmationPage from './pages/auth/PasswordResetConfirmationPage';
import SupportFormPage from './pages/support/SupportFormPage';
import DevicesPage from './pages/devices/DevicesPage';
import UsersPage from './pages/users/UsersPage';
import JourneysPage from './pages/journeys/JourneysPage';
import CompaniesPage from './pages/companies/CompaniesPage';
import EventsPage from './pages/events/EventsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import Portal from './components/layout/Portal';
import ToastContainer from './aurora/components/Toast/ToastContainer';
import ShippingProfilesPage from './pages/shippingProfiles/ShippingProfilesPage';
import { Text } from './aurora/typography/Text/Text';
import CustomerCostReportsPage from './pages/customerCostReports/CustomerCostReportsPage';
import CostCalculatorPage from './pages/customerCostReports/CostCalculatorPage';
import CostReportPage from './pages/customerCostReports/CostReportPage';
import PcbTestsPage from './pages/pcbTests/PcbTestsPage';
import JourneyPage from './pages/journeys/JourneyPage';
import FleetViewPage from './pages/fleetView/FleetViewPage';
import FeatureFlagsApi from './api/featureFlagsApi';

const App = () => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const { updateFeatureFlags, isLoading } = useFeatureFlags();
  const axiosPrivate = useAxiosPrivate();

  const refresh = useRefreshToken();
  const location = useLocation();

  useEffect(() => {
    const initialize = async () => {
      // Check authentication
      if (
        location.pathname.startsWith('/portal') ||
        location.pathname === '/'
      ) {
        try {
          await refresh();
        } catch (error) {
          console.error('Error refreshing token:', error);
        }

        // Fetch feature flags using new token
        try {
          await updateFeatureFlags(() =>
            FeatureFlagsApi.getFeatureFlags(axiosPrivate)
          );
        } catch (error) {
          console.error('Error fetching feature flags:', error);
        }
      }
      setIsAuthChecked(true);
    };

    initialize();
  }, []);

  if (!isAuthChecked || isLoading) {
    return (
      <Text size="md" sx={{ margin: '12px' }}>
        Loading...
      </Text>
    );
  }

  return (
    <div className="app">
      <div className="appContainer" id="app">
        <Routes>
          {/* open routes */}
          <Route path="login" element={<LoginPage />} />
          <Route path="register/:token" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="cost-report/:id" element={<CostReportPage />} />
          <Route
            path="/"
            element={<Navigate to={'/portal/fleet-view'} replace />}
          />

          {/* protected routes */}

          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ARTYC_ADMIN,
                  ARTYC_EMPLOYEE,
                  COMPANY_ADMIN,
                  COMPANY_EMPLOYEE,
                ]}
              />
            }
          >
            <Route
              path="registration-confirmation"
              element={<RegisterConfirmationPage />}
            />
            <Route
              path="password-reset-success"
              element={<PasswordResetConfirmationPage />}
            />
          </Route>

          {/* portal routes */}
          <Route path="/portal/" element={<Portal />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ARTYC_ADMIN,
                    ARTYC_EMPLOYEE,
                    COMPANY_ADMIN,
                    COMPANY_EMPLOYEE,
                  ]}
                />
              }
            >
              <Route
                path="home"
                element={<Navigate to={'/portal/fleet-view'} replace />}
              />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="fleet-view" element={<FleetViewPage />} />
              <Route path="shippers" element={<DevicesPage />} />
              <Route path="shipments" element={<JourneysPage />} />
              <Route path="journeys" element={<JourneysPage />} />
              <Route path="journey/:journey" element={<JourneyPage />} />
              <Route path="support" element={<SupportFormPage />} />
              <Route path="events" element={<EventsPage />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[ARTYC_ADMIN, ARTYC_EMPLOYEE, COMPANY_ADMIN]}
                />
              }
            >
              <Route path="users" element={<UsersPage />} />
              <Route
                path="shipping-profiles"
                element={<ShippingProfilesPage />}
              />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[ARTYC_ADMIN, ARTYC_EMPLOYEE]} />
              }
            >
              <Route path="pcb-tests" element={<PcbTestsPage />} />
              <Route path="companies" element={<CompaniesPage />} />
              <Route
                path="customer-cost-reports"
                element={<CustomerCostReportsPage />}
              />
              <Route path="cost-calculator" element={<CostCalculatorPage />} />
              <Route
                path="cost-calculator/:id"
                element={<CostCalculatorPage />}
              />
            </Route>
          </Route>

          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<Missing />} />
        </Routes>
      </div>
      <div className="toastContainer">
        <ToastContainer />
      </div>
      <div id="modalContainer" />
    </div>
  );
};

export default App;
