import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../../aurora/components/Checkbox/Checkbox';
import Dropdown from '../../../../aurora/components/Filter/Dropdown';
import { Label } from '../../../../aurora/typography/Label/Label';
import { toggleFilter } from '../../../../state/fleetView';
import { FleetViewFilter as FilterType } from '../../../../state/fleetView/types';
import { RootState } from '../../../../state/store';
import styles from './FleetViewFilter.module.scss';

const FleetViewFilter = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.fleetView.filter);
  const [open, setOpen] = useState(false);

  const popover = (
    <div className={styles.popover}>
      {Object.entries(filter).map(([key, value]) => {
        return (
          <div key={key} className={styles.popoverFilterItem}>
            <Checkbox
              managedChecked={value}
              onChange={() =>
                dispatch(
                  toggleFilter({ filter: key as keyof typeof FilterType })
                )
              }
              label={String(FilterType[key as keyof typeof FilterType])}
              labelClassName={styles.popoverFilterLabel}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <Dropdown
      variant={'filter'}
      text={
        <Label size="sm" color="sub600">
          Filter
        </Label>
      }
      popoverContent={popover}
      open={open}
      setOpen={setOpen}
    />
  );
};

export default FleetViewFilter;
