import React from 'react';
import StatusBadge from '../../../aurora/components/StatusBadge/StatusBadge';
import { FleetViewStatus } from '../../../state/fleetView/types';

interface Props {
  deploymentStatus?: FleetViewStatus;
}

const DeploymentStatusBadge = ({ deploymentStatus }: Props) => {
  return (
    <StatusBadge
      content={deploymentStatus || 'Unknown'}
      status={
        deploymentStatus === FleetViewStatus.DEPLOYED
          ? 'blue'
          : deploymentStatus === FleetViewStatus.ON_STANDBY
          ? 'gray'
          : 'orange'
      }
    />
  );
};

export default DeploymentStatusBadge;
