import { EntityState } from '../types';

export enum ShipperEnvSwitchStatus {
  PENDING = 'pending',
  SHIPPER_UPDATED = 'shipperUpdated',
  SWITCH_COMPLETE = 'switchComplete',
}

export enum EnvEnum {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export enum DeviceType {
  MedstowMicro = 'Medstow Micro',
  Medstow5LI = 'Medstow 5L-I',
}

interface LastHealthCheck {
  pluggedInAt: string;
  coolingPass: boolean;
  fullyCharged: boolean;
  cellConnected: boolean;
  gpsConnected: boolean;
  wifiConnected: boolean;
}

export interface DeviceEntity {
  _id: string;
  deviceType: DeviceType;
  serialNumber: string;
  companyName: string;
  companyId: string;
  pcbNumber: string;
  boardVersion: string;
  macAddress: string;
  firmwareVersion?: string;
  currentEnvironment: EnvEnum;
  envSwitchStatus: ShipperEnvSwitchStatus;
  newEnvironment?: EnvEnum | null;
  isInCurrentEnv: boolean;
  lastHealthCheck?: LastHealthCheck;
  cellEnabled: boolean;
}

export interface DeviceFilters {
  search?: string;
  companyIds?: string[];
  deviceTypes?: DeviceType[];
}

export interface DeviceState extends EntityState<DeviceEntity> {
  selectedFilters?: DeviceFilters;
  deviceTypeList: DeviceType[] | null;
}
